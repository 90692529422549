.dashboard-page {
  width: 100%;
}

.devices-statistics-chart {
  width: 450px;
  padding: 30px;
  box-shadow: 1px 1px 3px 2px #e6e6e6;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .devices-statistics-chart {
    width: 220px;
    height: 300px;
    padding: 0px;
  }
}

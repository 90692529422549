.loading-container {
  width: 100%;
  height: 100vh;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  .box {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border-top: 2px solid rgba(74, 174, 173, 1);
    animation: shake 1s infinite;
  }
  @keyframes shake {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

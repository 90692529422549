.logo-container {
    display: flex;
    justify-content: center;
    padding: 40px;
    transition:  0.7s ease; 
     img{
      width: 100px;
     }
  }
  

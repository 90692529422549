.ant-menu-dark .ant-menu-item-selected{
    background-color: #203c3c !important ;

}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding: 10px 0px;
}

.ant-menu-inline-collapsed {
  padding-inline: calc(50% - 8px - 15px);
}

.ant-pagination-item-active{
  border-color: rgba(74, 174, 173, 1) !important;
}

.ant-pagination-item-active a {
  color: #203c3c !important ;
}

.ant-pagination-item a {
  color: rgba(74, 174, 173, 1) !important;
}

.ant-drawer-body{
background-color: #203c3c !important ;;
}

@media (max-width: 768px) {
  .ant-table-thead th {
      font-size: 12px; 
  }
  
  .ant-table-tbody td {
      font-size: 12px; 
  }

  .ant-table-thead th,
  .ant-table-tbody td {
      padding: 8px;
  }

  .ant-table-cell {
      white-space: nowrap; 
      overflow: hidden; 
  }

  .ant-table-row-expand-icon-cell,
  .ant-table-expand-icon-th {
      display: none; 
  }
}
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  background-image: url("../../../assets/images/background.png");
  background-size: cover;

  .transparent-background {
    background-color: #4aafad28;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .form-container {
      background-color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      padding: 30px 30px 0 30px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.465);
      animation: shake 0.7s ease-in-out forwards;

      img {
        max-width: 150px;
      }

      .login-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
          text-align: center;
          font-size: 30px;
          margin: 10px;
          font-weight: 700;
          background: linear-gradient(
            168deg,
            rgba(109, 251, 251, 1) 0%,
            rgba(74, 174, 173, 1) 44%
          );
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
        }

        .input {
          height: 50px;
          width: 400px;
          border-radius: 20px;
        }
      }
    }
  }
}

.login-button {
  height: 50px;
  width: 200px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  border-radius: 20px;
  border: none;

  &:hover {
    transform: scale(1.05);
  }
}

.error{
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .login-page {
    .transparent-background {
      .form-container {
        padding: 40px 30px 0 30px;

        img {
          width: 120px;
        }

        .login-form {
          width: 320px;
          h1 {
            font-size: 28px;
            font-weight: bold;
            line-height: 1;
            margin: 15px 0;
          }

          .input {
            height: 50px;
            width: 320px;
            border-radius: 20px;
          }
        }

        .login-button {
          width: 320px;
        }
      }
    }
  }
}

@keyframes shake {
  0% {
    transform: translateY(-150px);
    opacity: 0;
  }
  50% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.menu-container{
  display: flex;
  height: calc(100vh - 152px);
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .menu {
    background-color: transparent !important;
    transition:  0.7s ease; 
  }
  .footer {
    color: white;
    text-align: center;
  }
}




 
  
.sider-container {
  background-color: #116262 !important ;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  .logo-container {
    display: flex;
    justify-content: center;
    padding: 40px;
    transition:  0.7s ease;
     img{
      width: 100px;
     } 
  }
}





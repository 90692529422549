.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white !important ;
  box-shadow: 2px 2px 6px 4px #e6e6e6;
  padding: 0 10px;
}

@media (max-width: 768px) {
  .hidden-mobile {
    display: none !important;
  }
}
